<template>
  <!-- NAME[epic=库管] 车库调拨统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="请选择仓库"
        >
          <el-option
            v-for="(i, idx) in depotSelect"
            :key="idx"
            :value="i.id"
            :label="i.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.guide"
          clearable
          style="width: 140px"
          placeholder="请选择调拨方向"
          @change="fetchData"
        >
          <el-option
            v-for="(i, idx) in diretcionSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.car_id"
          clearable
          multiple
          collapse-tags
          style="width: 180px"
          placeholder="请选择车辆"
        >
          <el-option
            v-for="(i, idx) in carSelect"
            :key="idx"
            :value="i.id"
            :label="i.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="">
        <brand-select
          :place="'商品品牌'"
          @brand-select-change="brandChange"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          :is-table="false"
          @input-goods="goodsSelect"
          @select-search-goods="addRows"
        ></goods-search>
      </el-form-item>

      <el-form-item label="" prop="">
        <el-select
          v-model="form.query_time"
          clearable
          style="width: 140px"
          placeholder="时间选择"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.allot_status"
          clearable
          style="width: 140px"
          placeholder="请选择状态"
        >
          <el-option
            v-for="(i, idx) in statusSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="handlersummary"
    >
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="100" align="center">
        <template #default="{ row }">
          <el-button type="text" @click="handleCheck(row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import brandSelect from '@/baseComponents/brandSelect'
  import goodsSearch from '@/baseComponents/goodsSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'CarDepotallot',
    components: { brandSelect, goodsSearch },
    data() {
      return {
        total: 0,
        time: [],
        list: [],
        loading: false,
        carSelect: [],
        // 状态1待审2已审4已取消5已作废6已冲改7已对账
        statusSelect: [
          { id: '1', name: '待审核' },
          { id: '2', name: '已审核' },
          { id: '4', name: '已取消' },
          { id: '5', name: '已作废' },
          // { id: '6', name: '已冲改' },
          // { id: '7', name: '已对账' },
        ],
        // 时间类型2申请时间1审核时间
        timeTypeSelect: [
          { id: '1', name: '审核时间' },
          { id: '2', name: '申请时间' },
        ],
        // 方向1仓库-车 2 车-仓库
        diretcionSelect: [
          { id: '1', name: '仓库>>车' },
          { id: '2', name: '车>>仓库' },
        ],
        layout: 'total, sizes, prev, pager, next, jumper',
        depotSelect: [],
        form: {
          pageSize: 10,
          pageNo: 1,
          goods_id: '', //商品ID
          brand_id: '', //品牌ID
          keyword: '', //产品名称/简拼
          depot_id: '', //仓库id
          car_id: [], //车辆id
          allot_status: '2', //状态1待审2已审4已取消5已作废6已冲改7已对账
          query_time: '2', //时间类型2申请时间1审核时间
          start_time: '', //开始时间
          end_time: '', //结束时间
          guide: '1', //方向1仓库-车 2 车-仓库
        },
        checkList: [
          '品牌',
          '商品名称/规格',
          '单位',
          '单价',
          '数量',
          '金额',
          '出货库',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 2,
            label: '商品名称/规格',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 3,
            label: '单位',
            prop: 'goods_unit',
            width: '',
          },
          {
            order: 4,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 5,
            label: '数量',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 6,
            label: '金额',
            prop: 'total_price',
            width: '',
          },
          {
            order: 7,
            label: '出货库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 8,
            label: '状态',
            prop: 'status_text',
            width: '',
          },
        ],
        heji_arr: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      this.initSelect()
    },
    methods: {
      handlersummary({ columns, data }) {
        let sums = []
        sums[0] = '总计'
        columns.forEach((list, index) => {
          if (list.label == '数量') {
            sums[index] = this.heji_arr.total_goods_num
          }
          if (list.label == '金额') {
            sums[index] = this.heji_arr.total_goods_price
          }
        })
        return sums
      },
      async initSelect() {
        let { data } = await postAction('/depotAdmin/depot-stock/allot-depot', {
          depot_type: 1,
        })
        this.depotSelect = data
        let car = await postAction('/depotAdmin/depot-stock/allot-depot', {
          depot_type: 2,
        })
        this.carSelect = car.data
      },
      async fetchData() {
        this.loading = true
        try {
          let { data, totalCount } = await postAction(
            '/depotAdmin/depot-stock/allot-form-list',
            this.form
          )
          this.heji_arr.total_goods_num = data.total_goods_num
          this.heji_arr.total_goods_price = data.total_goods_price
          this.total = totalCount
          this.list = data.goods_list
          this.loading = false
        } catch (err) {
          this.list = []
          this.loading = false
        }
      },
      classChange(v) {
        this.form.class_id = v
      },
      brandChange(v) {
        this.form.brand_id = v
      },
      goodsSelect(v) {
        this.form.keyword = v
      },
      addRows(v) {
        this.form.keyword = v
      },
      handleCheck(row) {
        console.log(row)
        this.$router.push({
          name: 'CarDepotAllotDetail',
          params: { ...this.form, ...row },
        })
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleExport() {
        //         /depotAdmin/depot-stock/allot-form-list-export
        // 车库调拨统计列表导出
        downloadFile(
          '/depotAdmin/depot-stock/allot-form-list-export',
          '车库调拨统计列表.xlsx',
          this.form
        )
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
